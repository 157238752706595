<template>
  <div class="p-b-10">
      <div class="platform-buttom-list" v-if="pageType === 'Detail'">
          <div :class="tagType===1? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(1)">账单列表</div>
          <div :class="tagType===2? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(2)">账单详情</div>
      </div>

      <component v-show="tagType === 1" :is="bottomListComponent"></component>
      <component v-show="tagType === 2" :is="bottomDetailComponent"></component>
  </div>
</template>

<script>
export default {
  components: {
    transactionList: () => import('../common/transactionList'),
    transactionDetail: () => import('../common/transactionDetail'),
    maintenanceList: () => import('../common/maintenanceList')
  },
  data () {
    return {
      tagType: 1,
      bottomListComponent: 'transactionList',
      bottomDetailComponent: ''
    }
  },
  created () {
    this.$store.commit('set_reconciliationDemo_pageType', 'List')
    this.bottomListComponent = this.moduleType + 'List'
  },
  methods: {

    handleChangeTag (val) {
      const componentName = val === 1 ? 'List' : 'Detail'
      this.$store.commit('set_reconciliationDemo_pageType', componentName)
    }
  },
  computed: {
    pageType () {
      return this.$store.state.reconciliationDemo.pageType
    },
    moduleType () {
      return this.$store.state.reconciliationDemo.moduleType
    }
  },
  watch: {
    pageType (val) {
      if (val === 'Detail') {
        this.tagType = 2
        this.bottomDetailComponent = 'transactionDetail'
      } else {
        this.tagType = 1
        this.bottomListComponent = this.moduleType + 'List'
      }
    },
    moduleType (val) {
      this.$store.commit('set_reconciliationDemo_pageType', 'List')
      this.bottomListComponent = val + 'List'
    }

  }
}
</script>
